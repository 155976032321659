.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* .signee-signatory-box-element {
  display: inline-block;
  background: #fff;
  border: 1px solid #ffd500;
  border-radius: 5px;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
} */

.firebaseui-idp-button .firebaseui-id-idp-button .firebaseui-idp-google {
  border: 1px solid #ffd500;
}

/* for document signee mobile */

/* Container Setup */
.container {
  width: 100%;
  overflow: hidden; /* Prevent children from overflowing */
  padding: 0px;
  padding-top: 10px;
}

/* Flexbox for Content Alignment */
.container-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 0px;
}

/* Responsiveness for Medium Screens (Tablets, small desktops) */
@media (min-width: 768px) {

  .container-content {
    flex-direction: row; /* Change to row layout on wider screens */
    /* justify-content: space-between; */
  }
}

/* Responsiveness for Large Screens (Desktops) */
@media (min-width: 1084px) {

  .container {
    padding-right: 5%;
    padding-left: 5%;
    width: 90%;
    box-sizing: content-box
}

  .container-content {
    justify-content: space-between;
  }
}

/* Very Large Screens (Extra large desktops) */
@media (min-width: 1400px) {

  .container {
    max-width: 1600px
}


  .container-content {
    justify-content: space-evenly; /* Distribute elements evenly on extra-large screens */
  }
}


/* Make sure all child elements inside container stay within its width */
.container {
  max-width: 100%;
  box-sizing: inherit; /* Inherit box-sizing from the container */
}

/* Example styles for child elements */
.container-content > div {
  padding: 0px;
  width: 100%;
}


/* Styles for small screen (mobile) */
@media (max-width: 480px) {

.container {
  padding-top: 20px;
}
  .container-content {
    flex-direction: column;
    align-items: center;
  }
}

.swiper {
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #5b3cc430;
  border-radius: 4px;
  overflow: "visible";
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-pagination {
  text-align: center;
  margin-top: 20px;
}

.custom-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #5b3cc4;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.custom-pagination .swiper-pagination-bullet-active {
  width: 13px;
  height: 13px;
  background-color: #5b3cc4;
  opacity: 1;
  transform: scale(1.2);
}

.swiper-button-next, .swiper-button-prev {
  color: #5b3cc4 !important; 
  width: 40px;
  height: 40px;
  transition: all 0.3s ease-in-out;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  background: #5b3cc4; 
  color: white !important;
  padding: 8px;
} 

.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 20px;
  font-weight: bold;
} 



