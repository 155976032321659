body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EEEBF8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.pdf_page_custom{
  position: relative;
  /* top: 0;
  left:0; */
  width: 100% ;
  height: 100% ;
  /* border: 10px solid red; */
}

.editor-box {
  display: flex;
  background: #dfdbf37e;
  border: 1px solid #604CC3 !important;
  opacity: 0.8;
  border-radius: 2px;
  border: 1px solid #DFDBF3;
  text-align: center;
  padding: 0px;
  box-sizing: border-box;
  /* margin-bottom: 10px; */
  overflow: hidden;
  position: relative;
  cursor: pointer;
  /* margin: 20px; */
  
}


.editor-box-element-with-text {
  display: inline-block;
  background: #E2EBF5;
  border: 1px solid #E2EBF5;
  border-radius: 2px;
  padding: 0px;
  box-sizing: border-box;
  /* margin-bottom: 10px; */
  overflow: hidden;
  position: relative;
  /* margin: 20px; */
}

.editor-box .text {
  /* text-align: center; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 36px;
}
.editor-box3 .react-resizable-handle {
  display: none;
}

.editor-box3 {
  background: #E2EBF5;
  border: 1px solid #E2EBF5;
  border-radius: 5px;
  padding: 0px;
}

.workspacemember-status-donutchart {
  height: 310px !important;
  margin-left: 108px !important;
  margin-top: 20px !important;
}

.workspacemember-status-donutchart-innertext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0%, -18%);
  text-align: center;
  pointer-events: none;
}

.workspacemember-status-donutchart-innertext-label {
  font-size: 16px;
  transform: translate(0%, 2%);
  color: #333;
}

.workspacemember-status-donutchart-innertext-value {
  font-size: 17px;
  transform: translate(0%, -4%);
  font-weight: bold;
  color: #333;
}

.workspacemember-status-donutchart-legend {
  transform: translate(03%, 45%);
}

.workspacemember-status-donutchart-legend-item {
  font-size: 16px;
}
