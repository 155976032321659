  .pdf_page_custom{
    position: 'relative';
    width:'100%';
    height:'100%';
  }
  
  .react-resizable {
    position: relative;
  }
  .react-resizable-handle {
    position: absolute;
    width: 15px;
    /* height: 15px; */
    bottom: 0;
    right: 0;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
  }
  #content {
    width: 100%;
    background: transparent;
    padding-bottom: 200px;
  }
  .layoutRoot {
    display: flex;
    flex-wrap: wrap;
  }
  .signee-box {
    display: inline-block;
    background: #E2EBF5;
    border: 1px solid #E2EBF5;
    opacity: 0.8;
    border-radius: 5px;
    text-align: center;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }

  .signee-box-element-with-text {
    display: inline-block;
    background: rgba(107, 177, 230, 0.2);
    border: 1px solid #006dea;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }

  .signee-signatory-box-element {
    display: inline-block;
    background: #fff;
    border: 1px solid #ffd500;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }

  .signee-box .text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 36px;
  }
  .signee-box3 .react-resizable-handle {
    display: none;
  }